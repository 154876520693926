var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","temporary":"","hide-overlay":"","right":"","width":"500px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-form',{ref:"form",staticStyle:{"height":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.saveLogin.apply(null, arguments)}}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"height":"100%"},attrs:{"loading":_vm.loading,"min-width":400,"flat":""}},[_c('v-app-bar',{staticClass:"edit_workspace_bar",attrs:{"flat":"","dense":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',[_vm._v("mdi-key-plus")])],1),_c('v-toolbar-title',{staticClass:"pl-0",domProps:{"innerHTML":_vm._s(_vm.title)}})],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-alert',{attrs:{"border":"top","color":"#daab39"}},[_c('v-icon',{attrs:{"color":"#daab39"}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t('warning.key_encryption'))+" ")],1)],1)],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{ref:"name_input",staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"color":"#DAAB39","label":_vm.$t('label.name'),"hide-details":"","required":"","suffix":""},model:{value:(_vm.form.name.value),callback:function ($$v) {_vm.$set(_vm.form.name, "value", $$v)},expression:"form.name.value"}})],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"color":"#DAAB39","label":_vm.$t('label.login'),"hide-details":"","required":""},model:{value:(_vm.form.login.value),callback:function ($$v) {_vm.$set(_vm.form.login, "value", $$v)},expression:"form.login.value"}},[_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.form.login.encrypted ? '#daab39' : '',"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.form.login.encrypted ? 'mdi-lock' : 'mdi-lock-open')},on:{"click":function($event){_vm.form.login.encrypted = !_vm.form.login.encrypted}}},'v-icon',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.encrypt'))}})])],1)],1),_vm._l((_vm.form.urls.value),function(value,index){return _c('v-row',{key:index,staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"color":"#DAAB39","label":_vm.$t('label.url'),"hide-details":"","required":""},model:{value:(_vm.form.urls.value[index]),callback:function ($$v) {_vm.$set(_vm.form.urls.value, index, $$v)},expression:"form.urls.value[index]"}},[_c('span',{attrs:{"slot":"prepend"},slot:"prepend"},[(index === _vm.form.urls.value.length -1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.form.urls.value.push('')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.add'))}})]):_vm._e()],1),_c('span',{attrs:{"slot":"append"},slot:"append"},[(index > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"float":"left"},attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.form.urls.value.splice(index, 1)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.remove'))}})]):_vm._e(),(index === 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.form.urls.encrypted ? '#daab39' : '',"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.form.urls.encrypted ? 'mdi-lock' : 'mdi-lock-open')},on:{"click":function($event){_vm.form.urls.encrypted = !_vm.form.urls.encrypted}}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.encrypt'))}})]):_vm._e()],1)])],1)}),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"type":_vm.show_password ? 'text' : 'password',"error-messages":_vm.errorPassword,"error-count":_vm.errorPasswordCount,"hide-details":_vm.errorPasswordCount === 0,"label":_vm.$t('label.password')},model:{value:(_vm.form.password.value),callback:function ($$v) {_vm.$set(_vm.form.password, "value", $$v)},expression:"form.password.value"}},[_c('span',{attrs:{"slot":"prepend"},slot:"prepend"},[_c('PasswordGenerator',{on:{"password":_vm.insertPassword}})],1),_c('span',{attrs:{"slot":"append"},slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.show_password ? 'mdi-eye' : 'mdi-eye-off')},on:{"click":function($event){_vm.show_password = !_vm.show_password}}},'v-icon',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.show_password'))}})])],1)])],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"color":"#DAAB39","label":_vm.$t('label.ip'),"type":"text","hide-details":""},model:{value:(_vm.form.ip.value),callback:function ($$v) {_vm.$set(_vm.form.ip, "value", $$v)},expression:"form.ip.value"}},[_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.form.ip.encrypted ? '#daab39' : '',"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.form.ip.encrypted ? 'mdi-lock' : 'mdi-lock-open')},on:{"click":function($event){_vm.form.ip.encrypted = !_vm.form.ip.encrypted}}},'v-icon',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.encrypt'))}})])],1)],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-textarea',{staticClass:"mt-2 pl-1 pr-1 mb-10",attrs:{"filled":"","color":"#daab39","height":"200","hide-details":"","label":_vm.$t('label.informations')},model:{value:(_vm.form.informations.value),callback:function ($$v) {_vm.$set(_vm.form.informations, "value", $$v)},expression:"form.informations.value"}},[_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.form.informations.encrypted ? '#daab39' : '',"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.form.informations.encrypted ? 'mdi-lock' : 'mdi-lock-open')},on:{"click":function($event){_vm.form.informations.encrypted = !_vm.form.informations.encrypted}}},'v-icon',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.encrypt'))}})])],1)],1)],2),_c('v-card-actions',{staticClass:"card-actions"},[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":""},on:{"click":_vm.closePanel}},[_vm._v(_vm._s(_vm.$t("button.cancel")))]),_c('v-btn',{attrs:{"small":"","color":"primary","text":"","type":"submit"}},[_vm._v(_vm._s(_vm.$t("button.submit")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }