var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"bg_appbar",attrs:{"app":"","height":"58"}},[(_vm.currentRouteName === 'Admin')?_c('span',{staticClass:"text_label_app_bar"},[_c('v-icon',[_vm._v("mdi-cogs")]),_vm._v("  "+_vm._s(_vm.$t('label.admin'))+" ")],1):(_vm.currentRouteName === 'Users')?_c('span',{staticClass:"text_label_app_bar"},[_c('v-icon',[_vm._v("mdi-account-group")]),_vm._v("  "+_vm._s(_vm.$t('label.users'))+" ")],1):(_vm.currentRouteName === 'Profile')?_c('span',{staticClass:"text_label_app_bar"},[_c('v-icon',[_vm._v("mdi-account-box")]),_vm._v("  "+_vm._s(_vm.$t('label.profile'))+" ")],1):(_vm.currentRouteName === 'Sessions')?_c('span',{staticClass:"text_label_app_bar"},[_c('v-icon',[_vm._v("mdi-account-clock")]),_vm._v("  "+_vm._s(_vm.$t('label.sessions'))+" ")],1):(_vm.currentRouteName === 'History')?_c('span',{staticClass:"text_label_app_bar"},[_c('v-icon',[_vm._v("mdi-clock")]),_vm._v("  "+_vm._s(_vm.$t('label.history'))+" ")],1):(_vm.showTrash)?_c('span',{staticClass:"text_label_app_bar"},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v("  "+_vm._s(_vm.$t('label.trash'))+" ")],1):(_vm.selectedFolder)?_c('span',{staticClass:"text_label_app_bar"},[_c('v-icon',[_vm._v(_vm._s(_vm.selectedFolder.icon))]),_vm._v(" "+_vm._s(_vm._f("str_limit")(_vm.selectedFolder.name,40))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","tile":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyFolderID(_vm.selectedFolder._id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}]),model:{value:(_vm.tooltip_copy),callback:function ($$v) {_vm.tooltip_copy=$$v},expression:"tooltip_copy"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tooltipFolderId)}})])],1):_vm._e(),_c('v-spacer'),_c('small',{attrs:{"id":"version"}},[_vm._v(" v"+_vm._s(_vm.version)+" ")]),(_vm.searchBar)?_c('v-text-field',{ref:"globalSearch",staticClass:"mr-2",attrs:{"flat":"","dense":"","clearable":"","hide-details":"","solo-inverted":"","placeholder":_vm.$t('label.search_bar')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchKeys.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-icon',{attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" mdi-search ")])],1):_vm._e(),_c('v-switch',{staticClass:"mr-2 mt-5",attrs:{"small":""},on:{"change":_vm.setTheme,"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{domProps:{"innerHTML":_vm._s(_vm.iconDarkMode)}})]},proxy:true}]),model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.flags[_vm.$vuetify.lang.current],"width":"20"}})],1)]}}])},[_c('v-list',_vm._l((_vm.langs),function(item,index){return _c('v-list-item',{key:index},[_c('v-btn',{attrs:{"text":"","small":"","block":""},on:{"click":function($event){return _vm.changeLang(item)}}},[_c('v-img',{attrs:{"src":_vm.flags[item],"width":"30"}})],1)],1)}),1)],1),(_vm.isPro)?_c('span',[_c('notification')],1):_vm._e(),(_vm.user)?_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-0 ml-2",attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v("mdi-account-box")])],1)]}}],null,false,1675938954)},[_c('v-list',{staticClass:"no-padding"},[(_vm.user.is_admin)?_c('span',[_c('v-list-item',{attrs:{"to":"/admin"}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-cogs")]),_vm._v(" "+_vm._s(_vm.$t('label.admin')))],1)],1),_c('v-divider')],1):_vm._e(),_c('v-list-item',{attrs:{"to":"/profile"}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.$t('label.profile')))],1)],1),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-logout")]),_vm._v(" "+_vm._s(_vm.$t('label.logout')))],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }