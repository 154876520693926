var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","temporary":"","hide-overlay":"","right":"","width":"500px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-form',{ref:"form",staticStyle:{"height":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.saveBank.apply(null, arguments)}}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"height":"100%"},attrs:{"loading":_vm.loading,"min-width":400,"flat":""}},[_c('v-app-bar',{staticClass:"edit_workspace_bar",attrs:{"flat":"","dense":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',[_vm._v("mdi-key-plus")])],1),_c('v-toolbar-title',{staticClass:"pl-0",domProps:{"innerHTML":_vm._s(_vm.title)}})],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-alert',{attrs:{"border":"top","color":"#daab39"}},[_c('v-icon',{attrs:{"color":"#daab39"}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t('warning.key_encryption'))+" ")],1)],1)],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{ref:"name_input",staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"color":"#DAAB39","label":_vm.$t('label.name'),"hide-details":"","required":"","suffix":""},model:{value:(_vm.form.name.value),callback:function ($$v) {_vm.$set(_vm.form.name, "value", $$v)},expression:"form.name.value"}})],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"color":"#DAAB39","label":_vm.$t('label.owner'),"hide-details":"","required":""},model:{value:(_vm.form.owner.value),callback:function ($$v) {_vm.$set(_vm.form.owner, "value", $$v)},expression:"form.owner.value"}},[_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.form.owner.encrypted ? '#daab39' : '',"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.form.owner.encrypted ? 'mdi-lock' : 'mdi-lock-open')},on:{"click":function($event){_vm.form.owner.encrypted = !_vm.form.owner.encrypted}}},'v-icon',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.encrypt'))}})])],1)],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"color":"#DAAB39","label":_vm.$t('label.bank_name'),"hide-details":"","required":""},model:{value:(_vm.form.bank_name.value),callback:function ($$v) {_vm.$set(_vm.form.bank_name, "value", $$v)},expression:"form.bank_name.value"}},[_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.form.bank_name.encrypted ? '#daab39' : '',"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.form.bank_name.encrypted ? 'mdi-lock' : 'mdi-lock-open')},on:{"click":function($event){_vm.form.bank_name.encrypted = !_vm.form.bank_name.encrypted}}},'v-icon',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.encrypt'))}})])],1)],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"type":_vm.show_iban ? 'text' : 'password',"label":_vm.$t('label.iban'),"hide-details":""},model:{value:(_vm.form.iban.value),callback:function ($$v) {_vm.$set(_vm.form.iban, "value", $$v)},expression:"form.iban.value"}},[_c('span',{attrs:{"slot":"append"},slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.show_iban ? 'mdi-eye' : 'mdi-eye-off')},on:{"click":function($event){_vm.show_iban = !_vm.show_iban}}},'v-icon',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.show_pin'))}})])],1)])],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"type":_vm.show_bic ? 'text' : 'password',"label":_vm.$t('label.bic'),"hide-details":""},model:{value:(_vm.form.bic.value),callback:function ($$v) {_vm.$set(_vm.form.bic, "value", $$v)},expression:"form.bic.value"}},[_c('span',{attrs:{"slot":"append"},slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.show_bic ? 'mdi-eye' : 'mdi-eye-off')},on:{"click":function($event){_vm.show_bic = !_vm.show_bic}}},'v-icon',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.show_pin'))}})])],1)])],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"type":_vm.show_card_number ? 'text' : 'password',"label":_vm.$t('label.card_number'),"hide-details":""},model:{value:(_vm.form.card_number.value),callback:function ($$v) {_vm.$set(_vm.form.card_number, "value", $$v)},expression:"form.card_number.value"}},[_c('span',{attrs:{"slot":"append"},slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.show_card_number ? 'mdi-eye' : 'mdi-eye-off')},on:{"click":function($event){_vm.show_card_number = !_vm.show_card_number}}},'v-icon',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.show_pin'))}})])],1)])],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"type":_vm.show_expiration_date ? 'text' : 'password',"label":_vm.$t('label.expiration_date'),"hide-details":""},model:{value:(_vm.form.expiration_date.value),callback:function ($$v) {_vm.$set(_vm.form.expiration_date, "value", $$v)},expression:"form.expiration_date.value"}},[_c('span',{attrs:{"slot":"append"},slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.show_expiration_date ? 'mdi-eye' : 'mdi-eye-off')},on:{"click":function($event){_vm.show_expiration_date = !_vm.show_expiration_date}}},'v-icon',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.show_pin'))}})])],1)])],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"input-field pl-1 pr-1 mb-2",attrs:{"type":_vm.show_cvc ? 'text' : 'password',"label":_vm.$t('label.cvc'),"hide-details":""},model:{value:(_vm.form.cvc.value),callback:function ($$v) {_vm.$set(_vm.form.cvc, "value", $$v)},expression:"form.cvc.value"}},[_c('span',{attrs:{"slot":"append"},slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.show_cvc ? 'mdi-eye' : 'mdi-eye-off')},on:{"click":function($event){_vm.show_cvc = !_vm.show_cvc}}},'v-icon',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.show_pin'))}})])],1)])],1),_c('v-row',{staticClass:"relative-row",attrs:{"dense":""}},[_c('v-textarea',{staticClass:"mt-2 pl-1 pr-1 mb-10",attrs:{"filled":"","color":"#daab39","height":"200","hide-details":"","label":_vm.$t('label.informations')},model:{value:(_vm.form.informations.value),callback:function ($$v) {_vm.$set(_vm.form.informations, "value", $$v)},expression:"form.informations.value"}},[_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.form.informations.encrypted ? '#daab39' : '',"tabindex":"-1"},domProps:{"innerHTML":_vm._s(_vm.form.informations.encrypted ? 'mdi-lock' : 'mdi-lock-open')},on:{"click":function($event){_vm.form.informations.encrypted = !_vm.form.informations.encrypted}}},'v-icon',attrs,false),on))]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('label.encrypt'))}})])],1)],1)],1),_c('v-card-actions',{staticClass:"card-actions"},[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":""},on:{"click":_vm.closePanel}},[_vm._v(_vm._s(_vm.$t("button.cancel")))]),_c('v-btn',{attrs:{"small":"","color":"primary","text":"","type":"submit"}},[_vm._v(_vm._s(_vm.$t("button.submit")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }