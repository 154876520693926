var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"60%","persistent":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.workspace_id)?_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-app-bar',{staticClass:"edit_workspace_bar",attrs:{"flat":"","dense":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',[_vm._v("mdi-share")])],1),_c('v-toolbar-title',{staticClass:"pl-0"},[_vm._v(" "+_vm._s(_vm.$t('title.share_workspace'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"x-small":"","text":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close-thick")])],1)],1),_c('v-card-text',{staticClass:"no-padding"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":10,"sort-by":"user_email","sort-desc":false,"footer-props":{'items-per-page-options':[10, 20, 50, 100]}},scopedSlots:_vm._u([{key:"header._id",fn:function(ref){return [_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":false,"bottom":"","offset-y":"","max-width":"1000","left":""},on:{"close":function($event){return _vm.$refs.addUserShare.emptyForm()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"float":"right"},attrs:{"x-small":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("button.add_user"))+" ")],1)]}}],null,true),model:{value:(_vm.menuAddUser),callback:function ($$v) {_vm.menuAddUser=$$v},expression:"menuAddUser"}},[_c('add-user-share',{ref:"addUserShare",attrs:{"selectedWorkspace":_vm.workspace_id},on:{"close":function($event){_vm.menuAddUser = false},"reload":function($event){return _vm.getUsers()}}})],1)]}},{key:"item.is_owner",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":"","disabled":item.is_owner},on:{"change":function($event){return _vm.changeOwner(item)}},model:{value:(item.is_owner),callback:function ($$v) {_vm.$set(item, "is_owner", $$v)},expression:"item.is_owner"}})]}},{key:"item.can_read",fn:function(ref){return [_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")])]}},{key:"item.can_write",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.rightChange(item)}},model:{value:(item.can_write),callback:function ($$v) {_vm.$set(item, "can_write", $$v)},expression:"item.can_write"}})]}},{key:"item.can_share",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.rightChange(item)}},model:{value:(item.can_share),callback:function ($$v) {_vm.$set(item, "can_share", $$v)},expression:"item.can_share"}})]}},{key:"item.can_export",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.rightChange(item)}},model:{value:(item.can_export),callback:function ($$v) {_vm.$set(item, "can_export", $$v)},expression:"item.can_export"}})]}},{key:"item.can_share_external",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.rightChange(item)}},model:{value:(item.can_share_external),callback:function ($$v) {_vm.$set(item, "can_share_external", $$v)},expression:"item.can_share_external"}})]}},{key:"item.expire_at",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.renderDate(item.expire_at, 'DD/MM/YYYY'))}})]}},{key:"item._id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticStyle:{"font-size":"16px"}},[_c('v-icon',[_vm._v("mdi-trash")]),_vm._v("  "+_vm._s(_vm.$t('warning.confirm_share_delete'))+" ")],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":""}},[_vm._v(_vm._s(_vm.$t('button.cancel')))]),_c('v-btn',{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.deleteShare(item._id)}}},[_vm._v(_vm._s(_vm.$t('button.confirm')))])],1)],1)],1)]}}],null,true)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }